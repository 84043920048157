import React, { useState, forwardRef, useImperativeHandle } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Modal, Row, Col, Button } from "react-bootstrap"

import Navigation from "./navigation"
import BookingForm from "./booking-form"

import "../styles/index.scss"

const Layout = forwardRef(({ title, children }, ref) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    showBookingRequestForm: () => {
      setShow(true);
    }
  }));

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? `${title} | ` : ''}{data.site.siteMetadata?.title}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-YQJ9EKGFNQ"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-YQJ9EKGFNQ');`}
        </script>
        <script>
          {`(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2947298,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>

      <Navigation siteTitle={data.site.siteMetadata?.title} onBookingFormClick={() => handleShow()} />

      <div>
        <main>{children}</main>

        <div style={{position: 'fixed', bottom: '2.25rem', left: 'calc(50% - 108px)', textAlign: 'center'}}>
          <Button variant="primary" size="lg" className="px-5 py-3 rounded-pill border-light border-4" style={{fontWeight: 600}} onClick={handleShow}>Book Now!</Button>
        </div>

        <Modal show={show} fullscreen="md-down" scrollable onHide={handleClose}>
          <BookingForm onCloseClick={() => handleClose()} />
        </Modal>

        <div style={{position: 'relative'}}>
          <div id="contactus" style={{position: 'absolute', top: '-70px'}}></div>
        </div>

        <footer className="bg-primary text-white font-weight-bold">
          <Container className="py-5">
            <h4 className="text-uppercase text-center font-weight-bold">Contact us</h4>

            <Row className="mt-3 text-center">
              <Col xs={12} md={4} className="my-2">
                <strong>Email</strong><br />
                <a className="link-light" href="mailto:hello@grasstopgames.com">hello@grasstopgames.com</a>
              </Col>
              <Col xs={12} md={4} className="my-2">
                <strong>Text or Call</strong><br />
                (630) 999-8020
              </Col>
              <Col xs={12} md={4} className="my-2">
                <strong>Follow Us</strong><br />
                <a href="https://www.facebook.com/GrassTopGames/" className="link-light" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-facebook-square fa-2x" aria-label="Facebook Page"></i></a>
                &nbsp;&nbsp;
                <a href="https://www.instagram.com/grasstopgames/" className="link-light" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-instagram fa-2x" aria-label="Instagram Page"></i></a>
              </Col>
            </Row>
          </Container>

          <div className="py-2" style={{backgroundColor: '#f47c26'}}>
            <Container className="text-center" style={{fontWeight: 500}}>
              &copy; {new Date().getFullYear()}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/rental-agreement" className="link-light text-decoration-none">Rental Agreement</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/terms" className="link-light text-decoration-none">Terms of Use</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/privacy" className="link-light text-decoration-none">Privacy Policy</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/cookies" className="link-light text-decoration-none">Cookie Policy</Link>
            </Container>
          </div>
        </footer>
      </div>
    </>
  )
})

export default Layout
