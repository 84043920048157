import React, { useState } from "react"
import { Link } from "gatsby"
import { Navbar, Offcanvas, Container, Nav, Button } from "react-bootstrap"
import Logo from "../images/logo.png"

const Navigation = ({ onBookingFormClick, siteTitle }) => {
  const [expanded, setExpanded] = useState(false);

  return <Navbar bg="secondary" variant="dark" expand="lg" sticky="top"
    expanded={expanded}
    onToggle={(e) => { setExpanded(e); }}
    onSelect={() => { setExpanded(false); }}
  >
    <Container>
      <Navbar.Brand as={Link} to="/">
        <img src={Logo} className="img-fluid" alt={siteTitle} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls={`offcanvasNavbar`} />

      <Navbar.Collapse className="d-none d-lg-block">
        <Nav className="ms-auto">
          <Nav.Link eventKey="game-menu" as={Link} to="/games">Game Menu</Nav.Link>
          <Nav.Link eventKey="faq" as={Link} to="/faq">FAQ</Nav.Link>
          <Nav.Link eventKey="rental-agreement" as={Link} to="/rental-agreement">Rental Agreement</Nav.Link>
          <Nav.Link eventKey="contactus" as={Link} to="#contactus">Contact Us</Nav.Link>
        </Nav>
        <Button className="ms-lg-3 text-uppercase font-weight-bold" onClick={() => { setExpanded(false); if (onBookingFormClick) onBookingFormClick(); }}><strong>Request Booking</strong></Button>
      </Navbar.Collapse>

      <Navbar.Offcanvas
        id={`offcanvasNavbar`}
        aria-labelledby={`offcanvasNavbarLabel`}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel`} style={{fontWeight: 600}}>Grass Top Games</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link eventKey="game-menu" as={Link} to="/games">Game Menu</Nav.Link>
            <Nav.Link eventKey="faq" as={Link} to="/faq">FAQ</Nav.Link>
            <Nav.Link eventKey="rental-agreement" as={Link} to="/rental-agreement">Rental Agreement</Nav.Link>
            <Nav.Link eventKey="contactus" as={Link} to="#contactus">Contact Us</Nav.Link>
          </Nav>
          <Button className="mt-4 text-uppercase font-weight-bold" onClick={() => { setExpanded(false); if (onBookingFormClick) onBookingFormClick(); }}><strong>Request Booking</strong></Button>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
}

export default Navigation
