import React, { useState } from "react"
import axios from "axios"
import { Row, Col, Alert, Modal, Form, FloatingLabel, Button, ButtonGroup } from "react-bootstrap"
import { parse, isValid } from "date-fns"
import Calendar from "./calendar"

import { formatPhoneNumber } from "../utilities"

const bundleDetails = [
  { id: 'bundle4', games: 4, price: 14000 }
]

const gameDetails = [
  { id: 'giant-4-to-score',           type: 'Favorite',   label: 'Giant 4-To-Score' },
  { id: 'giant-tumble-tower',         type: 'Favorite',   label: 'Giant Tumble Tower' },
  { id: 'backyard-pong',              type: 'Favorite',   label: 'Backyard Pong' },
  { id: 'corn-hole',                  type: 'Favorite',   label: 'Corn Hole' },
  { id: 'giant-checkers',             type: 'Favorite',   label: 'Giant Checkers' },
  { id: 'badminton',                  type: 'Favorite',   label: 'Badminton' },
  { id: 'battle-putt',                type: 'Favorite',   label: 'Battle Putt' },
  { id: 'endzone-toss',               type: 'Favorite',   label: 'Endzone Toss' },

  { id: 'giant-chess',                type: 'Deluxe',     label: 'Giant Chess' },
  { id: 'ping-pong-table-set',        type: 'Deluxe',     label: '6x3\' Ping Pong Table Set' },
  { id: 'pop-a-shot',                 type: 'Deluxe',     label: 'Pop-A-Shot' },

  { id: 'bocce',                      type: 'Classic',    label: 'Bocce' },
  { id: 'limbo',                      type: 'Classic',    label: 'Limbo' },
  { id: 'yard-dice',                  type: 'Classic',    label: 'Yard Dice (Yahtzee)' },
  { id: 'kanjam',                     type: 'Classic',    label: 'KanJam' },
  { id: 'ladder-toss',                type: 'Classic',    label: 'Ladder Toss' },
  { id: 'jumbo-left-right-center',    type: 'Classic',    label: 'Jumbo Left Right Center' },
  { id: 'frisbee-bottle-catch',       type: 'Classic',    label: 'Frisbee Bottle Catch' },
  { id: 'lawn-darts',                 type: 'Classic',    label: 'Lawn Darts' }
];

const partySupplyDetails = [
  { id: 'table',        step: 1,  max:  5,  label: 'Table ($20)' },
  { id: 'round-table',  step: 1,  max:  3,  label: 'Round Table ($10)' },
  { id: 'chairs',       step: 4,  max: 20,  label: 'Chairs (4 for $15)' },
  { id: 'bucket',       step: 1,  max:  3,  label: 'Drinks Bucket ($3)' },
];


function getBundle(id) {
  var bundles = bundleDetails.filter(b => b.id === id);
  if (bundles.length > 0)
    return bundles[0];
}

function getGame(id) {
  var games = gameDetails.filter(g => g.id === id);
  if (games.length > 0)
    return games[0];
}

function getGamesByType(type) {
  return gameDetails.filter(g => g.type === type);
}

function getPartySupply(id) {
  var partySupply = partySupplyDetails.filter(g => g.id === id);
  if (partySupply.length > 0)
    return partySupply[0];
}

const CustomFormControl = (props) => {
  const { label, onChange, isInvalid } = props;
  const [interacted, setInteracted] = useState(false);
  return <FloatingLabel label={label}>
    <Form.Control
      {...props}
      onChange={e => {
        if (!interacted) setInteracted(true);
        if (onChange) onChange(e);
      }}
      placeholder={label}
      isInvalid={interacted && isInvalid} />
  </FloatingLabel>
}


const BookingForm = ({ onCloseClick }) => {

  const steps = ['when', 'where', 'favorites', 'favorites-extra', 'deluxe-extra', 'classic-extra', 'supplies', 'contact', 'confirmation'];
  const [step, setStep] = useState('when');
  const [bundle, setBundle] = useState('bundle4');
  
  const [favorites, setFavorites] = useState([]);
  const [favoritesExtra, setFavoritesExtra] = useState([]);
  const [deluxeExtra, setDeluxeExtra] = useState([]);
  const [classicsExtra, setClassicsExtra] = useState([]);
  const [partySupplies, setPartySupplies] = useState({});

  const [date, setDate] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handlePrevStep() {
    const currentIndex = steps.indexOf(step);
    if (currentIndex === 0 && onCloseClick)
      onCloseClick();
    else if (currentIndex > 0)
      setStep(steps[currentIndex - 1]);
  }

  function handleNextStep() {
    const currentIndex = steps.indexOf(step);
    if (currentIndex === steps.length - 1 && onCloseClick)
      onCloseClick();
    else if (currentIndex < steps.length - 1 && currentIndex >= 0)
      setStep(steps[currentIndex + 1]);
  }

  function handleBundleClick(bundleId) {
    setBundle(bundleId);
    handleNextStep();
  }

  function handleFavoritesClick(gameId, checked) {
    var newGames = [...favorites];
    if (checked)
      newGames.push(gameId);
    else
      newGames = newGames.filter(g => g !== gameId);
    
    setFavorites(newGames);
  }

  function handleFavoritesExtraClick(gameId, checked) {
    var newGames = [...favoritesExtra];
    if (checked)
      newGames.push(gameId);
    else
      newGames = newGames.filter(g => g !== gameId);
    
    setFavoritesExtra(newGames);
  }

  function handleDeluxeExtraClick(gameId, checked) {
    var newGames = [...deluxeExtra];
    if (checked)
      newGames.push(gameId);
    else
      newGames = newGames.filter(g => g !== gameId);
    
    setDeluxeExtra(newGames);
  }

  function handleClassicsExtraClick(gameId, checked) {
    var newGames = [...classicsExtra];
    if (checked)
      newGames.push(gameId);
    else
      newGames = newGames.filter(g => g !== gameId);
    
    setClassicsExtra(newGames);
  }

  function handlePartySupplyClick(itemId, quantity) {
    var newSupplies = {...partySupplies};
    if (quantity > 0)
      newSupplies[itemId] = quantity;
    else if (newSupplies[itemId])
      delete newSupplies[itemId];
    
    setPartySupplies(newSupplies);
  }

  function validateWhen() {
    return (date !== null && date !== '' && isValid(parse(date, 'yyyy-MM-dd', new Date())));
  }

  function validateFavorites() {
    return favorites.length === getBundle(bundle).games;
  }

  function validateEvent() {
    var errors = {};

    if (address1 === null || address1 === '') errors.address1 = "Address Line 1 is required.";

    if (city === null || city === '') errors.city = "City is required.";

    if (state === null || state === '') errors.state = "State is required.";

    if (zipCode === null || zipCode === '') errors.zipCode = "Zip code is required.";
    else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/g.test(zipCode)) errors.zipCode = "Zip code is invalid.";

    return errors;
  }

  function validateContact() {
    var errors = {};

    if (firstName === null || firstName === '') errors.firstName = "First Name is required.";

    if (lastName === null || lastName === '') errors.lastName = "Last Name is required.";

    if (phone === null || phone === '') errors.phone = "Phone number is required.";
    else if (!/^\(\d{3}\) \d{3}-\d{4}$/g.test(phone)) errors.phone = "Phone number is invalid.";

    if (email === null || email === '') errors.email = "Email address is required.";
    // eslint-disable-next-line no-control-regex
    else if (!/^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/gi.test(email)) errors.email = "Email address is invalid.";

    return errors;
  }

  async function handleSubmitForm() {
    try
    {
      setLoading(true);
      setErrors({});
      var result = await axios.post('https://api2.grasstopgames.com/bookings/requests', {
        bundleId: bundle,
        gameIds: favorites.concat(
          favoritesExtra.map(g => `${g}+30`),
          deluxeExtra.map(g => `${g}+40`),
          classicsExtra.map(g => `${g}+10`)
        ),
        partySupplies: partySupplies,
        date,
        address1,
        address2,
        city,
        state,
        zipCode,
        firstName,
        lastName,
        phone,
        email
      });

      setLoading(false);
      if (result?.status === 200)
        setStep('sent');
      else
        setErrors({'': 'This is rare but something has gone wrong! Please email us at hello@grasstopgames.com, or text us at (630) 999-8020 and we will be able to handle your request.'});
    }
    catch (e) {
      setLoading(false);
      if (e?.response?.status === 400)
        setErrors({'': 'There was a problem with your submission. Please correct the errors below and try again:', ...e.response.data.errors});
      else
        setErrors({'': 'This is rare but something has gone wrong! Please email us at hello@grasstopgames.com, or text us at (630) 999-8020 and we will be able to handle your request.'});
    }
  }

  function hasErrors(errors) {
    return Object.keys(errors).length > 0;
  }

  return <>
    {step === 'when' &&
      <>
        <Modal.Header className="border-0 d-block">
          <div>
            <Row className="align-items-center">
              <Col xs={1}>
                <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
                  <i className="fa-solid fa-chevron-left"></i>
                </Button>
              </Col>
              <Col className="text-center">
                <strong>When is your event?</strong>
              </Col>
              <Col xs={1}>
              </Col>
            </Row>

            <Row className="text-center small g-0 mt-3">
              <Col style={{fontWeight: '500'}}>Su</Col>
              <Col>Mo</Col>
              <Col>Tu</Col>
              <Col>We</Col>
              <Col>Th</Col>
              <Col>Fr</Col>
              <Col style={{fontWeight: '500'}}>Sa</Col>
            </Row>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Calendar value={date || ''} onChange={v => setDate(v)} />
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant={validateWhen() ? 'primary' : 'outline-primary'} className="rounded-pill px-3 w-100 position-relative" disabled={!validateWhen()} onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            Next
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'where' && 
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Where is your event?</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={false} onSubmit={(e) => { e.preventDefault(); handleNextStep(); return false; }}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <CustomFormControl type="text" name="address1" label="Address Line 1" value={address1 || ''} onChange={e => { setAddress1(e.target.value) }} isInvalid={'address1' in validateEvent()} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <CustomFormControl type="text" name="address2" label="Address Line 2" value={address2 || ''} onChange={e => { setAddress2(e.target.value) }} isInvalid={'address2' in validateEvent()} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col xs={5}>
                      <CustomFormControl type="text" name="city" label="City" value={city || ''} onChange={e => { setCity(e.target.value) }} isInvalid={'city' in validateEvent()} />
                    </Col>
                    <Col xs={3}>
                      <CustomFormControl type="text" name="state" label="State" value={state || ''} onChange={e => { setState(e.target.value) }} maxLength="2" isInvalid={'state' in validateEvent()} />
                    </Col>
                    <Col xs={4}>
                      <CustomFormControl type="text" name="zipCode" label="Zip code" value={zipCode || ''} onChange={e => { setZipCode(e.target.value) }} maxLength="10" isInvalid={'zipCode' in validateEvent()} />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          </Form>

        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant={hasErrors(validateEvent()) ? 'outline-primary' : 'primary'} className="rounded-pill px-3 w-100 position-relative" disabled={hasErrors(validateEvent())} onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            Next
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'bundle' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Select your game bundle:</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs={8} className="text-center">
              <div className="d-flex flex-column">
                {bundleDetails.map(g =>
                  <Button variant="white" key={g.id} className="d-block mb-3 border py-3" style={{fontWeight: 500}} onClick={() => handleBundleClick(g.id)}>
                    <div>
                      <span style={{fontSize: '2.5rem', fontWeight: 700, lineHeight: 1}} className="text-primary">{g.games}</span><br />
                      <span style={{fontSize: '1rem', fontWeight: 700}}> Games</span><br />
                      ${g.price / 100}
                    </div>
                  </Button>)
                }
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </>
    }

    {step === 'favorites' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Select your <span className="text-primary">{getBundle(bundle).games}</span> games:</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xs={12}>
              {favorites.length > getBundle(bundle).games && <Alert variant="danger" className="small my-3">
                You have selected more than <strong>{getBundle(bundle).games}</strong> games.
                Please remove <strong>{(favorites.length)- getBundle(bundle).games}</strong> game{(favorites.length) - getBundle(bundle).games !== 1 ? 's' : ''}
              </Alert>}
            </Col>

            <Col>
              <h5 className="mb-3">Favorites</h5>
              {getGamesByType('Favorite').map(game =>
                <Button
                  key={game.id}
                  variant={favorites.filter(g => g === game.id).length > 0 ? "outline-primary" : "outline-dark"}
                  style={{fontWeight: favorites.filter(g => g === game.id).length > 0 ? "700" : "400"}}
                  className="mb-2 w-100 text-start"
                  onClick={(e) => { handleFavoritesClick(game.id, !(favorites.filter(g => g === game.id).length > 0)); e.target.blur() }}
                >
                  <i className={'fa-regular me-3 ' + (favorites.filter(g => g === game.id).length > 0 ? 'fa-square-check' : 'fa-square')}></i>
                  {game.label}
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant={validateFavorites() ? 'primary' : 'outline-primary'} className="rounded-pill px-3 w-100 position-relative" disabled={!validateFavorites()} onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            Next
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'favorites-extra' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Want to add additional Favorite games?</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <h5 className="mb-3">Favorites</h5>
              {getGamesByType('Favorite').filter(game => favorites.indexOf(game.id) < 0).map(game =>
                <Button
                  key={game.id}
                  variant={favoritesExtra.filter(g => g === game.id).length > 0 ? "outline-primary" : "outline-dark"}
                  style={{fontWeight: favoritesExtra.filter(g => g === game.id).length > 0 ? "700" : "400"}}
                  className="mb-2 w-100 text-start"
                  disabled={favorites.filter(g => g === game.id).length > 0}
                  onClick={(e) => { handleFavoritesExtraClick(game.id, !(favoritesExtra.filter(g => g === game.id).length > 0)); e.target.blur() }}
                >
                  <i className={'fa-regular me-3 ' + (favoritesExtra.filter(g => g === game.id).length > 0 ? 'fa-square-check' : 'fa-square')}></i>
                  {game.label} (+$30)
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant="primary" className="rounded-pill px-3 w-100 position-relative" onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            {favoritesExtra.length === 0 ? 'Skip' : 'Next'}
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'deluxe-extra' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Want to add any Deluxe games?</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <h5 className="mb-3">Deluxe</h5>
              {getGamesByType('Deluxe').map(game =>
                <Button
                  key={game.id}
                  variant={deluxeExtra.filter(g => g === game.id).length > 0 ? "outline-primary" : "outline-dark"}
                  style={{fontWeight: deluxeExtra.filter(g => g === game.id).length > 0 ? "700" : "400"}}
                  className="mb-2 w-100 text-start"
                  onClick={(e) => { handleDeluxeExtraClick(game.id, !(deluxeExtra.filter(g => g === game.id).length > 0)); e.target.blur() }}
                >
                  <i className={'fa-regular me-3 ' + (deluxeExtra.filter(g => g === game.id).length > 0 ? 'fa-square-check' : 'fa-square')}></i>
                  {game.label} (+$40)
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant="primary" className="rounded-pill px-3 w-100 position-relative" onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            {deluxeExtra.length === 0 ? 'Skip' : 'Next'}
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'classic-extra' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Want to add any Classic games?</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <h5 className="mb-3">Classics</h5>
              {getGamesByType('Classic').map(game =>
                <Button
                  key={game.id}
                  variant={classicsExtra.filter(g => g === game.id).length > 0 ? "outline-primary" : "outline-dark"}
                  style={{fontWeight: classicsExtra.filter(g => g === game.id).length > 0 ? "700" : "400"}}
                  className="mb-2 w-100 text-start"
                  onClick={(e) => { handleClassicsExtraClick(game.id, !(classicsExtra.filter(g => g === game.id).length > 0)); e.target.blur() }}
                >
                  <i className={'fa-regular me-3 ' + (classicsExtra.filter(g => g === game.id).length > 0 ? 'fa-square-check' : 'fa-square')}></i>
                  {game.label} (+$10)
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant="primary" className="rounded-pill px-3 w-100 position-relative" onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            {classicsExtra.length === 0 ? 'Skip' : 'Next'}
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'supplies' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Want to add any party supplies?</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <h5 className="mb-3">Party Supplies</h5>
              {partySupplyDetails.map(item =>
                <Row className="mb-2 align-items-center" key={item.id}>
                  <Col>
                    {item.label}
                  </Col>
                  <Col xs={1} className="text-center">
                    {partySupplies[item.id] || 0}
                  </Col>
                  <Col xs="auto">
                    <ButtonGroup>
                      <Button variant="outline-primary" onClick={() => handlePartySupplyClick(item.id, Math.max((partySupplies[item.id] || 0) - item.step, 0))}>-</Button>
                      <Button variant="outline-primary" onClick={() => handlePartySupplyClick(item.id, Math.min((partySupplies[item.id] || 0) + item.step, item.max))}>+</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant="primary" className="rounded-pill px-3 w-100 position-relative" onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            {Object.keys(partySupplies).length === 0 ? 'Skip' : 'Next'}
          </Button>
        </Modal.Footer>
      </>
    }


    {step === 'contact' &&
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>How can we get in touch with you?</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={false} onSubmit={(e) => { e.preventDefault(); setStep('confirmation'); return false; }}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <CustomFormControl type="text" name="firstName" label="First Name" value={firstName || ''} onChange={e => { setFirstName(e.target.value) }} isInvalid={'firstName' in validateContact()} />
                    </Col>
                    <Col>
                      <CustomFormControl type="text" name="lastName" label="Last Name" value={lastName || ''} onChange={e => { setLastName(e.target.value) }} isInvalid={'lastName' in validateContact()} />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <CustomFormControl type="text" name="phone" label="Phone" value={phone || ''} onChange={e => { var val = formatPhoneNumber(e.target.value); setPhone(val) }} isInvalid={'phone' in validateContact()} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <CustomFormControl type="text" name="email" label="Email" value={email || ''} onChange={e => { setEmail(e.target.value) }} isInvalid={'email' in validateContact()} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button variant={hasErrors(validateContact()) ? 'outline-primary' : 'primary'} className="rounded-pill px-3 w-100 position-relative" disabled={hasErrors(validateContact())} onClick={() => handleNextStep()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            Next
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'confirmation' && 
      <>
        <Modal.Header className="border-0">
          <Col xs={1}>
            <Button variant="link" className="rounded-circle" onClick={() => handlePrevStep()}>
              <i className="fa-solid fa-chevron-left"></i>
            </Button>
          </Col>
          <Col className="text-center">
            <strong>Confirm your booking:</strong>
          </Col>
          <Col xs={1}>
          </Col>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              {Object.keys(errors).length > 0 && <Alert variant="danger">
                {errors[''] !== null && <>{errors['']}</>}
                {Object.keys(errors).filter(k => k !== '').length > 0 && <ul className="mb-0 mt-3">
                  {Object.keys(errors).filter(k => k !== '').map(k => <li key={k}>{errors[k]}</li>)}
                </ul>}
              </Alert>}

              <p>Please confirm your requested booking details below. If everything looks correct, click the submit button.</p>
              <p>You may also review our Rental Agreement by <a href="/rental-agreement" target="_blank">clicking here</a>.</p>

              <strong>Bundle:</strong><br />
              ${getBundle(bundle).price / 100} - {getBundle(bundle).games} Games<br />
              {favorites.map(g => <span key={g}>- {getGame(g).label}<br /></span>)}
              <br />

              {(favoritesExtra.length > 0 || deluxeExtra.length > 0 || classicsExtra.length > 0) && <>
                <strong>Add-ons:</strong><br />
                {favoritesExtra.map(g => <span key={g}>- {getGame(g).label} (+$30)<br /></span>)}
                {deluxeExtra.map(g => <span key={g}>- {getGame(g).label} (+$40)<br /></span>)}
                {classicsExtra.map(g => <span key={g}>- {getGame(g).label} (+$10)<br /></span>)}
                <br />
              </>}

              {Object.keys(partySupplies).length > 0 && <>
                <strong>Party Supply Add-ons:</strong><br />
                {Object.keys(partySupplies).map(g => <span key={g}>- {getPartySupply(g).label}: {partySupplies[g]}<br /></span>)}<br />
              </>}

              <strong>When:</strong><br />
              {parse(date, 'yyyy-MM-dd', new Date()).toLocaleDateString()}<br /><br />

              <strong>Where:</strong><br />
              {address1}<br />
              {address2 && <>{address2}<br /></>}
              {city} {state} {zipCode}<br /><br />

              <strong>Your Details:</strong><br />
              {firstName} {lastName}<br />
              {phone}<br />
              {email}<br /><br />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button className="rounded-pill px-3 w-100 position-relative" disabled={loading} onClick={() => handleSubmitForm()}>
            <i className="fa-solid fa-chevron-right position-absolute top-0 end-0 h-100 me-3 d-flex align-items-center"></i>
            Submit Booking Request
          </Button>
        </Modal.Footer>
      </>
    }

    {step === 'sent' &&
      <Modal.Body>
        <Row>
          <Col>
            <p className="text-center my-4">
              Thank you for your interest. We'll contact you within 24 hours to confirm that the games are available on the date you selected.<br />
              <br />
              <Button className="rounded-pill" onClick={() => { onCloseClick && onCloseClick() }}>Close</Button>  
            </p>
          </Col>
        </Row>
      </Modal.Body>
    }
  </>
}

export default BookingForm
